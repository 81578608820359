.card-member-stat {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1rem;
	margin-bottom: 1rem;
}

@media (max-width: 768px) {
	.card-member-stat {
		grid-template-columns: 1fr;
	}
}

.card-member-stat--item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	border-radius: 0.5rem;
	background-color: var(--white);
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.card-member-stat--summary {
	font-size: 1.25rem;
	font-weight: 700;
}

.card-member-stat--summary span {
	color: var(--green);
	font-size: 1.5rem;
}
