@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;700&display=swap");
html {
	font-family: "Kanit", sans-serif !important;
}

body {
	font-family: "Kanit", sans-serif !important;
}

.h1,
h1 {
	font-size: 30px;
}

.topbar {
	height: 3rem !important;
}

.sidebar hr.sidebar-divider {
	margin: 0px !important;
}

.sidebar .nav-item .nav-link {
	padding: 8px 10px;
}

.sidebar-dark #sidebarToggle {
	margin-top: 20px;
}

.table td,
.table th {
	padding: 3px 10px;
	color: #000;
	background: #e1e1e1;
}

.table td {
	font-size: 13px;
	background: none;
}

.table .btn-circle {
	margin: 0px 3px;
}

.tab-content {
	background: #fff;
	padding: 10px;
	border: 1px solid #ededed;
	color: #323030;
}

.login_by {
	width: 100%;
	text-align: center;
}

.login_by td {
	border: 1px solid #eee;
	padding: 10px 0px;
}

.login_by i {
	font-size: 25px;
}

.login_by .fa-check-circle {
	color: #289d41;
}

.login_by .fa-times-circle {
	color: #f83d3d;
}

.profile_info {
	width: 100%;
}

.profile_info td {
	padding: 5px 10px;
	border: 1px solid #eee;
}

.profile_info td:first-child {
	text-align: right;
	width: 30%;
}

.imgProfile {
	border: 2px dotted #eee;
	padding: 4px;
	background: #fff;
	margin: 20px 0px;
}

.emailEx {
	border: 2px dotted #eee;
	padding: 10px;
	background: #fff;
	margin: 20px 0px;
}

.circle {
	border-radius: 50%;
}

.btn-secondary {
	color: #fff;
	background-color: #4e73df;
	border-color: #4e73df;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #4e73df;
	border-color: #4e73df;
}

.nav-tabs li {
	padding: 10px 0px;
	border: 1px solid #ccc;
	margin-right: 10px;
}

.nav-tabs li a {
	padding: 10px;
}

.nav-tabs .active {
	background: #4e73df;
	border-bottom: 0;
	color: #fff;
}

.member_list .fa-check-circle {
	color: #289d41;
	font-size: 20px;
}

.member_list .fa-times-circle {
	color: #f83d3d;
}

.member_list .react-bs-container-body {
	height: 350px !important;
}

.member_list td {
	font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.member_list .react-bs-table-pagination {
	padding: 0px 20px;
}

.box_filter {
	margin-bottom: 20px;
}

.modal-content {
	color: #323030;
}

.mt-10 {
	margin-top: 10rem;
}

.custom-rigth {
	float: left;
}

.row-label {
	display: flex;
	flex-wrap: wrap;
	margin-right: -0.75rem;
}

/* ul
{
    list-style-type: none;
    display: contents;
} */

.btn-disabled {
	pointer-events: none;
	background-color: #e1e1e1;
	border-color: #e1e1e1;
}

.text-link-disabled {
	pointer-events: none;
	color: #e1e1e1;
}

.form-slagname {
	display: flex;
	width: auto;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #fff;
	background-color: #6e707e;
	background-clip: padding-box;
	border: 1px solid #d1d3e2;
	border-radius: 0.35rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.text-ellipsis {
	white-space: nowrap;
	width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.file-list {
	cursor: pointer;
	display: grid;
	column-gap: 10px;
	align-items: center;
}

.file-list:hover {
	color: #4e73df !important;
}

.delete-file {
	cursor: pointer;
	margin-left: 10px;
}

.delete-file:hover {
	color: #e22b2b !important;
	cursor: pointer;
	margin-left: 10px;
}

li.list-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 4px;
	padding-top: 2px;
	padding-bottom: 2px;
}

li.list-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}

div.list-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 4px;
	padding-top: 2px;
	padding-bottom: 2px;
}
div.list-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}

.table-task {
	list-style: none;
	padding: 0;
}

.table-task ul {
	margin-bottom: 0.5rem;
}
.table-task li {
	font-weight: lighter;
}

.table-task li label {
	margin: 0;
	padding: 0;
	font-weight: 600;
}

.table-task li label span {
	margin-left: 0.5rem;
	font-weight: normal;
	color: #4e73df;
}

.table-task li span {
	margin-left: 0.5rem;
	font-weight: normal;
	color: #4e73df;
	/* color: #289d41; */
}

.table-task li span.guest {
	margin-left: 0.5rem;
	font-weight: normal;
	color: rgba(255, 140, 0);
}
