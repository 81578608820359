
.form-heading {
	text-align: center;
	margin-bottom: 25px;
}
.form-container  {
	margin: 20px auto;
}
.form-style {
	margin: 10px 0;
}
.form-style h2 {
	font-family: "Kanit", sans-serif !important;
	padding: 0;
	margin: 0 0 20px;
	text-align: center;
	font-size: 25px;
	color: #000;
	font-weight: bold;
}
.form-style .label-form {
	display: block;
	margin: 0 0 5px 0;
	color: #262626;
	padding: 0;
}
.form-style .field {
	margin-bottom: 15px;
}
.form-style .field-button {
	margin-top: 20px;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr;
}
.form-style .input-text {
	width: 100%;
}
.form-style .timeout {
	margin-top: 20px;
	font-size: 11px;
	text-align: center;
}
.form-style .timeout span {
	color: #E51C1C;
}
.button-outline {
	color: #4e73df;
	border: 1px solid #4e73df;
	background: transparent;
}