
  .table-compare table {
    border-collapse:collapse; 
  }
  .table-compare   {

    overflow-x:scroll;  
    overflow-y:visible;
    padding-bottom:10px;

  }
  .table-compare tr{
    position: relative;
    
  }
  .table-compare img{
    object-fit: cover;
  }
  .table-compare  thead th {
    font-size: 20px;
  }
  .table-compare  td,
  .table-compare  th {
    margin:0;
    border:2px solid grey; 
    vertical-align: top;
    white-space:break-spaces;
    padding: 8px;
  }
  .table-compare  h5 {
    font-size: 16px;
    margin: 0;
  }

  .table-compare .headcol {
    /* position:absolute; 
    width:12em; 
    left:65px;
    top:auto;
    height: 100%;
    border-top-width:2px;
 
    white-space:nowrap */
    background: white;
    position: sticky;
    left: 0;
    padding: 8px;
}
  .table-compare .headcol:before {
    content: "";
    width: 2px;
    height: 100%;
    background: grey;
    left: -1px;
    position: absolute;
    top: 0;
}
  .table-compare .headcol:after {
    content: "";
    width: 2px;
    height: 100%;
    background: grey;
    right: -1px;
    position: absolute;
    top: 0;
}

.table-compare .tr-text {
  word-break: break-word;
  width: 300px;
}

.table-compare .tr-require-text {
  color: red;
  width: 300px;
}